import { type PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({
  children,
  root,
  inactive,
  attachPositionToEl,
}: PropsWithChildren<{ root?: string; inactive?: boolean; attachPositionToEl?: RefObject<HTMLElement | null> }>) => {
  const [mount, setMount] = useState<HTMLElement | null>();
  const customId = useRef<string | undefined>();

  const checkForWrapper = () => {
    if (typeof document === undefined) {
      return;
    }

    document.getElementById('scroll-mainContent')?.removeEventListener('scroll', handleScrollEvent);
    document.getElementById('scroll-mainContent')?.addEventListener('scroll', handleScrollEvent);
    const wrapper = document.getElementById(root ?? 'portal-root');
    if (wrapper) {
      let customWrapper;
      if (attachPositionToEl?.current) {
        if (!attachPositionToEl?.current.closest('#scroll-mainContent')) {
          throw new Error('Cannot have attachPositionToEl and not be a child of id="scroll-mainContent"');
        }
        const rects = attachPositionToEl?.current?.getBoundingClientRect();
        customWrapper = document.createElement('div');
        customWrapper.style.position = 'absolute';
        customWrapper.style.pointerEvents = 'none';
        customWrapper.style.zIndex = '99';
        customId.current = `custom-${rects.top}-${rects.right}-${rects.bottom}-${rects.left}`;
        customWrapper.id = customId.current;
        updateElementPosition(customWrapper);
        document.body.appendChild(customWrapper);
      }
      setMount(document.getElementById(customId.current ?? root ?? 'portal-root'));
    } else {
      setTimeout(() => {
        checkForWrapper();
      });
    }
  };

  useEffect(() => {
    if (!mount) {
      checkForWrapper();
    }
    return () => {
      if (customId.current) {
        document.getElementById(customId.current)?.remove();
        customId.current = undefined;
      }
      document.getElementById('scroll-mainContent')?.removeEventListener('scroll', handleScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateElementPosition = (el: HTMLElement) => {
    const rects = attachPositionToEl!.current!.getBoundingClientRect();

    el.style.top = `${rects.top}px`;
    el.style.left = `${rects.left}px`;
    el.style.height = `${rects.height}px`;
    el.style.width = `${rects.width}px`;
  };

  const handleScrollEvent = () => {
    if (inactive || !customId.current || !document.getElementById(customId.current)) {
      return;
    }
    updateElementPosition(document.getElementById(customId.current)!);
  };

  if (inactive) {
    return children;
  }

  if (!mount) {
    return null;
  }

  return createPortal(children, mount);
};

export default Portal;
